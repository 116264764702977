%heading {
  font-family: var(--font-primary);
  font-weight: 400;
}

.heading-1 {
  @extend %heading;
  font-size: 4.5rem;
  color: var(--color-grey-light-2);
  line-height: 1;
  font-weight: bold;
}

.heading-2 {
  @extend %heading;
  font-size: 3rem;
  line-height: 1;
  font-weight: bold;

  &--dark {
    color: var(--color-primary-dark);
  }

  &--light {
    color: var(--color-primary-light);
  }
}

.heading-3 {
  @extend %heading;
  font-size: 2.2rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;

  &--dark {
    color: var(--color-grey-dark-1);
  }

  &--light {
    color: var(--color-grey-light-2);
  }
}

.heading-4 {
  @extend %heading;
  font-size: 1.8rem;
  // letter-spacing: 1px;
  font-weight: bold;

  &--dark {
    color: var(--color-grey-dark-1);
  }

  &--light {
    color: var(--color-grey-light-1);
  }
}

.heading-5 {
  @extend %heading;
  font-size: 1.6rem;
  letter-spacing: 1px;
  font-family: var(--font-secondary);

  &--dark {
    color: var(--color-grey-dark-2);
  }

  &--light {
    color: var(--color-grey-light-2);
  }
}

.heading-6 {
  @extend %heading;
  font-size: 1.4rem;
  font-family: var(--font-secondary);
  letter-spacing: 1px;
}

.heading-7 {
  @extend %heading;
  font-size: 2.5rem;
  letter-spacing: 2px;
  font-family: Georgia;
}

.btn {
  background-color: #000; //var( --color-grey-dark-1);
  color: var(--color-grey-light-2);
  border: 1px solid var(--color-grey-dark-2);
  border-radius: 0;
  border-width: 1px;
  font-family: var(--font-secondary);
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 1rem 0.5rem;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: var(--color-primary-dark);
  }
}

.mb-sm {
  margin-bottom: 2rem;
}

.mb-md {
  margin-bottom: 3rem;
}

.mb-lg {
  margin-bottom: 4rem;
}

.mb-hg {
  margin-bottom: 8rem;
}
