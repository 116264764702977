.features {
  grid-column: full-start / full-end;
  grid-row: feature-start / feature-end;
  background-color: #ebebeb;
  padding-bottom: 3rem;

  &__wrap {
    width: 100%;
    max-width: 768px;
    height: 25rem;
    position: relative;
    opacity: 0;
    transition: 1s;
    top: 1rem;

    .feature {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem 2rem;
      letter-spacing: 1px;
      width: 100%;
      margin: 1rem auto;

      &__icon {
        fill: var(--color-primary-dark);
        width: 5rem;
        height: 5rem;
        padding-bottom: 1rem;
      }

      &__heading {
        padding-bottom: 1rem;
      }

      &__text {
        text-align: justify;
        letter-spacing: 0.5px;
        height: 10rem;
      }
    }
  }

  &--circle {
    display: block;
    position: relative;
    top: -4%;

    & div {
      position: absolute;
      top: -5rem;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    &--1 {
      left: 50%;
      margin-left: -5.5rem;
    }

    &--2 {
      left: 50%;
      margin-left: -3.5rem;
    }

    &--3 {
      left: 50%;
      margin-left: -1.5rem;
    }

    &--4 {
      left: 50%;
      margin-left: 0.5rem;
    }

    &--5 {
      left: 50%;
      margin-left: 2.5rem;
    }

    &--6 {
      left: 50%;
      margin-left: 4.5rem;
    }
  }
}
@media screen and (min-width: 768px) {
  .features {
    padding-top: 5rem;

    &__wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      /*
      Firefox's width is greater than 1200px,
      so it needs margin-left and margin-right to keep center
      */
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
      // position: static;
      height: 100%;

      .feature {
        width: calc(100% / 3 - 30px);
        position: static;

        &__icon {
          width: 5rem;
          height: 5rem;
        }
      }
    }

    &--circle {
      display: none;

      & div {
        position: static;
        opacity: 0;
      }
    }
  }
}
