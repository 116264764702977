// DEFINE CSS GRID
.container {
  width: 100%;
  display: grid;
  grid-template-rows:
    [header-start]
    10vh
    [header-end cover-start]
    80vh
    [cover-end feature-start]
    min-content
    [feature-end projects-start]
    minmax(90vh, min-content)
    [projects-end gallery-start]
    min-content
    [gallery-end news-start]
    // 100vh
    // minmax(100vh, min-content)
    min-content
    [news-end about-start]
    // 100vh
    minmax(100vh, min-content)
    [about-end description-start]
    min-content
    [description-end contact-start]
    min-content
    [contact-end footer-start]
    min-content
    [footer-end];
  grid-template-columns:
    [full-start]
    1fr
    [content-start]
    repeat(8, [col-start] minmax(min-content, 1fr) [col-end])
    [content-end]
    1fr
    [full-end];
}

.container2 {
  width: 100%;
  display: grid;
  grid-template-rows:
    [header-start]
    10vh
    [header-end newsdetail-start]
    minmax(80vh, min-content)
    [newsdetail-end footer-start]
    10vh
    [footer-end];
  grid-template-columns:
    [full-start]
    1fr
    [content-start]
    repeat(8, [col-start] minmax(min-content, 1fr) [col-end])
    [content-end]
    1fr
    [full-end];
}

@media screen and (min-width: 1200px) {
  .container2 {
    grid-template-rows:
      [header-start]
      10vh
      [header-end newsdetail-start]
      80vh
      [newsdetail-end footer-start]
      10vh
      [footer-end];
  }
}
