.footer {
  grid-column: full-start / full-end;
  grid-row: footer-start / footer-end;
  background-color: #ebebeb;
  text-align: center;
  line-height: 85px;
  font-size: 1rem;
  font-family: var(--font-secondary);
  letter-spacing: 1px;
  font-weight: 400;
}
