.newsdetail {
  grid-column: full-start / full-end;
  grid-row: newsdetail-start / newsdetail-end;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;

  &__newslist {
    -webkit-appearance: none;
    margin-top: 2rem;

    &--items1 {
      display: none;
    }

    &--items2 {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 1rem;
      width: 90%;
      font-size: 1.4rem;
      box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
      outline: none;
      margin-bottom: 2rem;
    }
  }

  &__content {
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    margin-top: 3%;
    margin-right: 1rem;

    &--img {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &--main {
        display: block;
        position: relative;
        overflow: hidden;

        & > div {
          position: absolute;
          top: 0;
          left: 0;
          display: none;
        }

        & img {
          width: 100%;
          vertical-align: top;
        }
      }

      &--others {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin-top: 4%;

        &--items {
          width: 23%;
          margin-right: 2%;
          margin-left: 2%;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          & img {
            width: 100%;
            margin: 0 auto;
            vertical-align: top;
          }
        }
      }
    }

    &--text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      & p {
        margin-bottom: 1rem;
      }

      &--date {
        margin-top: 3rem;
      }

      &--title {
        margin-bottom: 1rem;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .newsdetail {
    flex-direction: row;

    & > div {
      flex-basis: 0;
    }

    &__newslist {
      flex-grow: 1.5;
      overflow: auto;
      text-align: center;
      padding-top: 6.5%;
      margin-top: 0;
      height: 80vh;

      &--items1 {
        display: block;
        font-size: 1.5rem;
        color: #666666;
        // margin-bottom: 2rem;
        width: auto;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;

        &:hover {
          color: #c69c1d;
        }

        &::after {
          content: "";
          width: 100%;
          margin: 15% auto 15%;
          display: block;
          border: 0.5px solid #aaaaaa;
          transform: rotate(180deg);
        }
      }

      &--items2 {
        display: none;
      }
    }

    &__content {
      flex-grow: 8.5;
      flex-direction: row;
      margin-right: 1rem;

      &--img {
        margin-bottom: auto;
        margin-left: 3rem;
        flex-basis: 0;
        flex-grow: 4.5;
      }

      &--text {
        flex-basis: 0;
        flex-grow: 5.5;
        height: 100%;
        margin-left: 2rem;

        &--date {
          margin-top: 0;
          line-height: 1.8rem;
        }
      }
    }
  }
}
