.description {
  text-align: center;
  position: relative;
  padding-bottom: 5rem;

  &__text {
    width: auto;
    max-width: 30rem;
    margin-right: auto;
    margin-left: auto;
    text-align: justify;
    color: #fff;
    padding: 0 0 3rem;
  }

  &__button {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    width: auto;
    max-width: 30rem;
    margin-right: auto;
    margin-left: auto;

    &--item {
      margin: 0 0.5rem;
    }
  }
}
@media screen and (min-width: 768px) {
  .description {
    &__text {
      max-width: 46rem;
    }

    &__button {
      max-width: 46rem;
    }
  }
}