// COLOR VARIABLES

:root {
  --color-primary-dark: #9d790a;
  --color-primary-light: #c69c1d;
  // --color-secondary: #1f1f1f;
  --color-grey-dark-1: #1b1b1b;
  --color-grey-dark-2: #595959;
  --color-grey-light-1: #606060;
  --color-grey-light-2: #ffffff;
  // FONT VARIABLES
  --font-primary: "Noto Serif TC", serif;
  --font-secondary: "Noto Sans TC", sans-serif;
  // --font-display: 'Josefin Sans', sans-serif;
  // RESPONSIVE BREAKPOINTS
  --bp-largest: 75em;
  // 1200px
  --bp-large: 62.5em;
  // 1000px
  --bp-medium: 50em;
  // 800px;
  --bp-small: 37.5em;
  // 600px;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 75%; // 12px/16px = 75% -> 1rem = 12px
  @media only screen and (max-width: var(--bp-largest)) {
    font-size: 50%;
  }
}

body {
  font-family: var(--font-primary);
  color: var(--color-primary);
  font-weight: 300;
  line-height: 1.6;
}
