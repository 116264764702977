.descriptionModal {
  &__header {
    position: relative;

    &--btn {
      width: 3rem;
      height: 3rem;
      z-index: 1;
      position: absolute;
      left: 95%;
      top: -1.5rem;
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;

    & img {
      margin-top: 5rem;
      height: auto;
      width: auto;
      display: block;
      margin: auto;
    }
  }

  & p {
    font-size: 1.6rem;
    text-align: center;
    font-weight: bold;
  }
}
@media screen and (min-width: 768px) {
  .descriptionModal {
    &__header {
      &--btn {
        left: 99%;
      }
    }

    &__content {
      & img {
        margin-top: 5rem;
      }
    }
  }
}