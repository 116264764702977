.contact {
  grid-column: full-start / full-end;
  grid-row: contact-start / contact-end;
  background-color: #f6f6f6;
  padding: 7rem 1rem 5rem;

  &__text {
    width: 100%;
    color: var(--color-secondary);

    &--title {
      margin-bottom: 3rem;
      color: var(--color-primary);
    }

    &--information {
      margin-top: 3rem;
      margin-bottom: 4rem;
      font-size: 2rem;

      &--address {
        & > b {
          font-weight: bold;
          margin-right: 1rem;
        }
      }

      &--phone {
        & > b {
          font-weight: bold;
          margin-right: 1rem;
        }
      }

      &--fax {
        & > b {
          font-weight: bold;
          margin-right: 1rem;
        }
      }
    }
  }

  &__map {
    width: 100%;

    & > div {
      height: 400px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .contact {
    display: flex;
    justify-content: flex-end;
    padding: 0;

    &__text {
      width: 30%;
      padding: 10rem 0;
    }

    &__map {
      width: 50%;

      & > div {
        height: 100%;
      }
    }
  }
}