.cover {
  grid-column: full-start / full-end;
  grid-row: cover-start / cover-end;
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 5rem;

  &__background {
    &__img {
      width: 100%;
      height: 80vh;
      position: absolute;
      top: 0;
      left: 0;
      color: transparent;
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      z-index: 0;
    }
  }

  &__text {
    z-index: 3;
    margin: auto;
    text-align: center;
    display: flex;

    &--title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 0.5rem;

      & h1:first-child {
        font-size: 2rem;
      }

      & h1:last-child {
        font-size: 1.4rem;
        color: #666;
      }
    }

    & > span {
      color: #fff;
      margin: auto;
      font-size: 3rem;
    }
  }
}
@media screen and (min-width: 768px) {
  .cover {
    &__text {
      &--title {
        & h1:first-child {
          font-size: 3rem;
        }

        & h1:last-child {
          font-size: 1.5rem;
        }
      }

      & > span {
        font-size: 4rem;
      }
    }
  }
}
