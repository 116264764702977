.modal {
  &__header {
    width: 100%;

    &--btn {
      width: 5rem;
      height: 5rem;
      z-index: 1;
      position: absolute;
      right: -3%;
      top: -3rem;
      cursor: pointer;
      opacity: 1;
    }

    @-moz-document url-prefix() {
      &--btn {
        right: -1%;
      }
    }
  }

  &__content {
    background-color: transparent;
    height: 100%;

    & figure {
      background-color: transparent;
      overflow: visible;
      display: inline-block;
      width: auto;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    & img {
      max-width: 100%;
      width: auto !important;
      display: block;
      margin-left: auto;
      margin-right: auto;
      transform: none !important;
    }
  }
}
