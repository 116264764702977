.projects {
  grid-column: full-start / full-end;
  grid-row: projects-start / projects-end;
  padding-bottom: 5rem;

  &__cover {
    position: relative;
    margin-bottom: 5rem;
    overflow: hidden;
    width: 100%;

    &__header {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      padding-top: 7rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      opacity: 0;
      transition: 1s;
      position: relative;
      top: 1rem;

      &--title {
        word-break: keep-all;
        font-size: 5rem;
      }

      &--content {
        margin-top: 2rem;
        width: 100%;
        font-size: 2rem;
        color: #fff;
        margin-bottom: 7rem;
      }
    }
  }

  &__timeline {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    &--nav {
      width: 3rem;
      height: 3rem;
      padding: 0.7rem;
      z-index: 1;
      fill: #fff;
      background-color: var(--color-primary-light);
      outline: none;
      border: none;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }

    &--line {
      border: 1px solid var(--color-grey-dark-2);
      width: 100%;
      height: 0;
      margin-top: auto;
      margin-bottom: auto;
      visibility: hidden;
      position: relative;

      &--circles {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: var(--color-primary-light);
        top: 0;
        transform: translate(-50%, -50%);
        transition: 1s;
      }

      &--year {
        position: absolute;
        transform: translate(-50%, -50%);
        width: 150px;
        height: 50px;
        background-color: var(--color-primary-light);
        color: #fff;
        text-align: center;
        line-height: 50px;
        user-select: none;
        transition: 0.5s;
        left: 50%;
        top: 50%;
        visibility: visible;
      }
    }
  }

  &__cards {
    width: calc(100% - 2rem);
    border: 1px solid #ddd;
    margin: 3rem auto 0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    opacity: 0;
    transition: 1s;

    &__content {
      width: 100%;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      transition-property: opacity, left;
      transition-duration: 0.25s, 1s;
      position: absolute;
      opacity: 0;

      &__img {
        flex-basis: 0;
        flex-grow: 6;
        margin-right: auto;
        margin-left: auto;
        max-height: 100%;

        & img {
          width: auto;
          max-width: 100%;
          user-select: none;
        }
      }

      &__text {
        flex-basis: 0;
        flex-grow: 4;
        font-size: 1.6rem;

        &--title {
          font-size: 1rem;
          padding: 0.5rem 1rem;
          margin-top: 5%;
          margin-right: auto;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: space-between;

          & span {
            & svg {
              display: inline-block;
              width: 2.5rem;
              height: 2.5rem;
              padding: 0.5rem 0.5rem 0.5rem 0;
              fill: none;
              stroke: #000;
              stroke-width: 1px;
              stroke-linecap: round;
              stroke-linejoin: round;
              margin-top: auto;
              margin-bottom: auto;
            }

            & a {
              text-decoration: none;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          & h1 {
            min-width: 16rem;
            background-color: var(--color-primary-light);
            text-align: center;
          }
        }

        &--content {
          font-size: 1.4rem;
          margin-top: 3%;
          padding: 1%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          font-family: var(--font-secondary);
          letter-spacing: 1px;

          & p {
            margin-bottom: 1rem;
            text-align: justify;
          }

          & > ul {
            margin-bottom: auto;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            & > li > p {
              text-align: left;
            }
          }
        }
      }
    }

    &__sidebar {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid #ddd;

      &__tags,
      &__links {
        height: 100%;
        user-select: none;

        & p {
          display: inline-block;
          margin-right: 1rem;
          font-weight: bold;
        }

        & span {
          display: inline-block;
          line-height: 50px; //等於高度，即可置中
          text-align: center;
          cursor: pointer;
          font-size: 1rem;
        }
      }

      &__tags {
        display: flex;
        width: auto;
        max-width: 100%;

        & span {
          width: 9rem;
          border-right: 1px solid #ddd;
          color: #666666;

          &:hover {
            color: #c69c1d;
          }
        }
      }

      &__links {
        display: none;

        & span {
          & svg {
            display: inline-block;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0.5rem 0.5rem 0.5rem 0;
            fill: none;
            stroke: #000;
            stroke-width: 1px;
            stroke-linecap: round;
            stroke-linejoin: round;
            margin-top: auto;
            margin-bottom: auto;
          }

          & a {
            text-decoration: none;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 960px) {
  .projects {
    &__cover {
      height: 35vh;
      margin-bottom: 12rem;

      & img {
        height: 100%;
      }

      &__header {
        padding-top: 0;
        flex-direction: row;
        width: 60%;
        height: 100%;

        &--content {
          width: 60%;
          margin-top: auto;
          margin-bottom: auto;
          line-height: 3rem;
        }
      }
    }

    &__timeline {
      width: 70%;

      &--line {
        visibility: visible;

        &--year {
          left: 20%;
          top: auto;

          &::before {
            content: "";
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
            border-top: 10px solid var(--color-primary-light);
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
        }
      }
    }

    &__cards {
      width: 70%;
      flex-direction: column;

      &__content {
        flex-direction: row;
        top: 0;

        &__img {
          flex-grow: 4.5;
          margin-top: auto;
          margin-bottom: auto;
          max-height: 100%;
          overflow: hidden;
        }

        &__text {
          flex-grow: 5.5;
          padding-left: 3rem;
          padding-right: 3rem;
          height: 32rem;
          overflow-y: auto;

          &--title {
            margin-top: 0;
            padding: 0 2rem;
            font-size: 1.4rem;

            & span {
              display: none;
            }

            & h1 {
              min-width: 22rem;
            }
          }

          &--content {
            font-size: 1.4rem;
          }
        }
      }

      &__sidebar {
        justify-content: space-between;
        border-bottom-width: 0;
        border-top: 1px solid #ddd;

        &__tags,
        &__links {
          & span {
            width: 10rem;
          }
        }

        &__links {
          display: inline-block;

          & span {
            border-left: 1px solid #ddd;
          }
        }
      }
    }
  }
}
