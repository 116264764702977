.header {
  grid-column: full-start / full-end;
  grid-row: header-start / header-end;
  background-color: var(--color-grey-dark-1);
  position: relative;
  height: 10vh;
  width: 100%;
  z-index: 100;
  transition: 0.5s;
  display: flex;
  align-items: center;

  &__logo {
    margin-left: 5%;
    display: flex;
    align-items: center;

    & img {
      vertical-align: bottom;
    }
  }

  #headerControl {
    display: none;
  }

  #headerControl:checked ~ nav > ul {
    left: 0;
    top: 10vh;
    height: 100vh;
    width: 100%;
    font-size: 2.2rem;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.5s;
    z-index: 5;
  }

  #headerControl:checked ~ nav > ul > li,
  nav > ul > a {
    text-align: center;
    margin: 5% 0;
    list-style: none;
    text-decoration: none;

    &:nth-child(n + 2)::before {
      content: "";
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2.5rem;
      display: block;
      border: 0.2px solid #3a3a3a;
      transform: rotate(180deg);
    }
  }

  &__nav {
    &__list {
      list-style: none;
      position: absolute;
      top: -200px;
      left: 0;

      &--item {
        color: #fff;
        cursor: pointer;
      }
    }
  }

  &__line {
    width: 6rem;
    height: 3.5rem;
    fill: #fff;
    position: absolute;
    right: 3%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &__back {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    cursor: pointer;
    transition: 1s;

    & > svg {
      width: 6rem;
      height: 4rem;
      fill: #b3b3b3;
    }
  }
}
@media screen and (min-width: 768px) {
  .header {
    justify-content: space-between;

    &__nav {
      margin-right: 10%;

      &__list {
        position: static;
        display: flex;
        letter-spacing: 3px;
        height: 100%;

        &--item {
          font-size: 1.2rem;
          padding: 0 2rem;
          height: 100%;
          display: flex;
          align-items: center;

          &:hover {
            background-color: var(--color-grey-dark-2);
          }

          &--link:link,
          &--link:visited {
            color: var(--color-grey-light-2);
          }
        }
      }
    }

    & label {
      display: none;
    }
  }
}
