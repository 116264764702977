.about {
  grid-column: full-start / full-end;
  grid-row: about-start / about-end;
  background: linear-gradient(
    135deg,
    rgba(14, 14, 13, 0.9) 0,
    rgba(14, 14, 13, 0.9) 25%,
    rgba(44, 44, 44, 1) 25%,
    rgba(44, 44, 44, 1) 50%,
    rgba(14, 14, 13, 0.9) 50%,
    rgba(14, 14, 13, 0.9) 75%,
    rgba(44, 44, 44, 1) 75%,
    rgba(44, 44, 44, 1)
  );
  background-size: 5px 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 0 5rem;

  &__top {
    display: flex;
    flex-direction: column;
    padding-bottom: 10%;

    &__description {
      height: 444px;
      width: 100%;
      position: relative;

      &__title {
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: 1s;
        top: 5%;

        & > div {
          margin-top: 7rem;
          margin-bottom: 10%;
        }
      }
    }

    &__text {
      height: 250px;
      width: 100%;
      max-width: 768px;
      /*
      Firefox's width is greater than 1200px,
      so it needs margin-left and margin-right to keep center
      */
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      position: relative;
      opacity: 0;
      transition: 1s;
      top: 1rem;

      &--item {
        margin-left: auto;
        text-align: center;
        position: absolute;
        width: 100%;
        top: 10%;
      }

      &--engtitle {
        color: #4e4e4e;
      }

      &--line {
        display: none;
      }

      &--circle {
        position: relative;
        top: 6rem;
        cursor: pointer;

        &--1 {
          position: absolute;
          top: 0;
          left: -5.5rem;
          border-radius: 50%;
          width: 1rem;
          height: 1rem;
        }

        &--2 {
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -0.5rem;
          border-radius: 50%;
          width: 1rem;
          height: 1rem;
        }

        &--3 {
          position: absolute;
          top: 0;
          left: 4.5rem;
          border-radius: 50%;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  &__story {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    opacity: 0;
    transition: 1s;
    top: 1rem;

    &__btn {
      width: 4rem;
      height: 4rem;
      fill: #aaa;
      position: absolute;
      padding: 1rem;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;

      &.left {
        top: 40%;
        left: 0;
      }

      &.right {
        top: 40%;
        right: 0;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }

    &--item {
      width: 100%;
      margin-top: -5%;

      &__img {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        vertical-align: bottom;
      }

      &__text {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: #fff;
        background-clip: content-box;

        &--title {
          padding-top: 3rem;
          font-size: 1.8rem;
          padding-bottom: 2rem;
        }

        &--content {
          height: 200px;
          padding: 0 2rem 3rem;
          text-align: justify;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .about {
    &__top {
      &__text {
        flex-grow: 3;
      }
    }
  }
}
@media screen and (min-width: 960px) {
  .about {
    padding-left: 0;
    padding-right: 0;

    &__top {
      flex-direction: row;
      padding-bottom: 0;
      height: 100%;

      &__description {
        height: 58rem;
        flex-basis: 0;
        flex-grow: 7;

        &__title {
          top: 55%;
          transform: translate(-50%, -50%);

          & > div {
            margin-top: 0;
          }
        }
      }

      &__text {
        flex-direction: column;
        justify-content: space-between;
        margin-top: 1rem;
        margin-bottom: 1rem;
        flex-basis: 0;
        flex-grow: 2.5;
        flex-wrap: nowrap;
        height: 56rem;
        min-width: 32rem;

        &--item {
          text-align: left;
          margin-left: 4rem;
          margin-right: 1rem;
          margin-top: 1rem;
          position: static;
          opacity: 1;
          width: 80%;
        }

        &--line {
          display: block;
          width: 80%;
        }

        &--circle {
          display: none;

          & div {
            position: static;
            opacity: 0;
          }
        }
      }
    }

    &__story {
      // position: static;
      height: 50vh;
      margin-top: 10%;

      &__btn {
        width: 6rem;
        height: 5rem;
        fill: var(--color-grey-dark-2);
        position: static;
        background-color: transparent;
      }

      &--item {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        position: relative;
        width: 62vw;
        padding: 0;
        margin-top: -5rem;
        margin-bottom: 5rem;

        &__img {
          position: relative;
          left: -2vw;
          top: 0;
          width: 31vw;
        }

        &__text {
          text-overflow: ellipsis;
          overflow: hidden;
          position: relative;
          left: 1vw;
          top: 5rem;
          width: 31vw;
          padding: 5% 3rem 33.86%;
          height: 0;
          background-clip: border-box;
        }
      }
    }
  }
}
@media screen and (max-width: 1360px) and (min-width: 961px) {
  .about {
    &__story {
      &--item {
        width: 70vw;

        &__text {
          width: 37vw;
          top: 6rem;
          padding: 3% 1.5rem 32.86%;
          font-size: 1.4rem;

          &--title {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
        }

        &__img {
          width: 33vw;
        }
      }
    }
  }
}
