.news {
  grid-column: full-start / full-end;
  grid-row: news-start / news-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 1rem 5rem;
  width: 100%;

  &__title {
    padding-bottom: 5rem;
  }

  &__story {
    display: flex;
    flex-direction: column;
    border: 1px solid #878787;
    // opacity: 0;
    transition: 1s;

    &__img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;

      & img {
        width: 100%;
        vertical-align: top;
      }
    }

    &__article {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 2rem 0 2rem;
      color: black;

      &__date {
        margin-top: 2rem;
      }

      &__title {
        margin-bottom: 1rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      & > div {
        position: relative;
      }

      &__content {
        margin-bottom: 1rem;
        text-align: justify;

        &__btn {
          cursor: pointer;
          color: blue;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }

  &__list {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    // opacity: 0;
    transition: 1s;

    &__item {
      position: relative;
      margin-bottom: 3rem;

      &__title {
        margin-left: 5%;
      }

      &__content {
        margin-left: 5%;
        font-size: 1.4rem;
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &__btn {
          position: absolute;
          left: 84%;
          top: 75%;
          cursor: pointer;
          margin-top: 0.2rem;
          color: blue;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }
}
@media screen and (min-width: 960px) {
  .news {
    grid-column: col-start 2 / span 6;

    &__story {
      flex-direction: row;
      overflow: hidden;

      &__img {
        width: 55%;
        height: 100%;
      }

      &__article {
        width: 45%;
        height: 0;
        padding: 12% 0 16%;

        &__date {
          margin-top: 3rem;
        }

        &__content {
          text-align: justify;
          margin-bottom: 0;
        }
      }
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;

      &__item {
        overflow: hidden;
        width: calc(100% / 2);
        left: 2%;
        padding: 0 1rem;

        &__content {
          width: 60%;

          &__btn {
            left: 69%;
            top: 75%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 961px) {
  .news {
    grid-column: content-start / content-end;
  }
}
