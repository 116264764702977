.gallery {
  grid-column: full-start / full-end;
  grid-row: gallery-start / gallery-end;
  overflow: hidden;
  height: 40vh;
  width: 100%;
  position: relative;
  padding: 10rem 5rem 0;
  opacity: 0;
  transition: 1s;

  &__item {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__img {
    width: 100%;
    object-fit: cover;
  }

  & svg {
    opacity: 0;
  }
}
@media screen and (min-width: 960px) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(4, 7vw);
    grid-gap: 0.5rem;
    padding: 1rem;
    height: 100%;

    &__item {
      opacity: 1;
      overflow: hidden;
      position: relative;

      &--1 {
        grid-row: 1 / span 2;
        grid-column: 1 / span 2;
      }

      &--2 {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
      }

      &--3 {
        grid-row: 4 / span 1;
        grid-column: 1 / span 1;
      }

      &--4 {
        grid-row: 1 / span 1;
        grid-column: 3 / span 1;
      }

      &--5 {
        grid-row: 2 / span 1;
        grid-column: 3 / span 1;
      }

      &--6 {
        grid-row: 3 / span 2;
        grid-column: 2 / span 2;
      }

      &--7 {
        grid-row: 1 / span 3;
        grid-column: 4 / span 3;
      }

      &--8 {
        grid-row: 4 / span 1;
        grid-column: 4 / span 1;
      }

      &--9 {
        grid-row: 4 / span 1;
        grid-column: 5 / span 1;
      }

      &--10 {
        grid-row: 4 / span 1;
        grid-column: 6 / span 1;
      }

      &--11 {
        grid-row: 1 / span 1;
        grid-column: 7 / span 1;
      }

      &--12 {
        grid-row: 1 / span 1;
        grid-column: 8 / span 1;
      }

      &--13 {
        grid-row: 2 / span 2;
        grid-column: 7 / span 2;
      }

      &--14 {
        grid-row: 4 / span 1;
        grid-column: 7 / span 1;
      }

      &--15 {
        grid-row: 4 / span 1;
        grid-column: 8 / span 1;
      }

      &--16 {
        grid-row: 1 / span 2;
        grid-column: 9 / span 2;
      }

      &--17 {
        grid-row: 3 / span 2;
        grid-column: 9 / span 2;
      }
    }

    &__img {
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }
}
